import * as React from "react";
import "normalize.css";
import styled from "@emotion/styled";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

const MainWrapper = styled.main`
  text-align: center;
  color: #fff;
  font-family: "Open Sans", sans-serif;

  ::selection {
    background: #e89f17;
  }
`;

const Main = (props) => {
  const { children, lang } = props;
  return (
    <GoogleReCaptchaProvider
      language={lang === "ua" ? "uk" : "en"}
      useRecaptchaNet
      reCaptchaKey="6LeJna4ZAAAAAGUIYdudGiM3-Y4u1BzThTdF8wSU"
      scriptProps={{ async: true, defer: true, appendTo: "body" }}
    >
      <MainWrapper>{children}</MainWrapper>
    </GoogleReCaptchaProvider>
  );
};

export default Main;
