import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import styled from "@emotion/styled";

const BgImageStyled = styled(BgImage)`
  width: 100%;
  height: 100%;
  min-width: 100vw;
  min-height: 100vh;
`;

const ForestBackgroundImage = (props) => {
  const { forest } = useStaticQuery(
    graphql`
      query {
        forest: file(relativePath: { eq: "DJI_0087_Forest.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 2000
              quality: 70
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  );
  const pluginImage = getImage(forest);

  return (
    <BgImageStyled image={pluginImage} fadeIn durationFadeIn={1500} critical>
      {props.children}
    </BgImageStyled>
  );
};

export default ForestBackgroundImage;
